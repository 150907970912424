<template>
  <el-main>
    <page-title show-back-btn />
    <div v-loading="loading.detail" class="partition-area">
      <el-form label-width="240px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="资金方基本信息">
          <el-col :span="12">
            <el-form-item label="资金方名称">
              <p>{{ utils.isEffectiveCommon(companyInfo.capRel.capName) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="资金方编码">
              <ics-item-inner :prop="companyInfo.capRel.capCode" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="统一社会信用代码">
              <ics-item-inner :prop="companyInfo.capRel.capSocialNo" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态">
              <ics-item-inner :prop="companyInfo.capRel.enabled" :format="(val)=>utils.statusFormat(Number(val), 'enableStatus')" />
            </el-form-item>
          </el-col>
        </ics-page-inner>
        <ics-page-inner title="资金信息">
          <el-row :gutter="40" style="margin-left: 32px;">
            <el-col :span="5">
              <div class="show box flex">
                产品数量
              </div>
              <div class="show2 flex">
                {{ companyInfo.capStat.productNumber }}
              </div>
            </el-col>
            <el-col :span="5">
              <div class="show box flex">
                累计授信融资方数
              </div>
              <div class="show2 flex">
                {{ utils.moneyFormat(companyInfo.capStat.customerCreditNum) }}
              </div>
            </el-col>
            <el-col :span="6" style="margin-left: 25px;">
              <div class="show box flex">
                融资方累计授信
              </div>
              <div class="show2 flex">
                {{ utils.moneyFormat(companyInfo.capStat.customerCreditAmount) }}
              </div>
            </el-col>
            <el-col :span="6" style="margin-left: 26px;">
              <div class="show box flex">
                累计授信笔数
              </div>
              <div class="show2 flex">
                {{ utils.moneyFormat(companyInfo.capStat.creditNum) }}
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="80" style="margin-left: 31px;">
            <el-col :span="5">
              <div class="show box flex">
                累计放款
              </div>
              <div class="show2 flex">
                {{ utils.moneyFormat(companyInfo.capStat.loanAmount) }}
              </div>
            </el-col>
            <el-col :span="5" style="margin-left: -9px;">
              <div class="show box flex">
                累计收款
              </div>
              <div class="show2 flex">
                {{ utils.moneyFormat(companyInfo.capStat.collectAmount) }}
              </div>
            </el-col>
            <el-col :span="6" style="margin-left: 25px;">
              <div class="show box flex">
                实时放款
              </div>
              <div class="show2 flex">
                {{ utils.moneyFormat(companyInfo.capStat.currLoanAmount) }}
              </div>
            </el-col>
            <el-col :span="6" style="margin-left: 18px;">
              <div class="show box flex">
                待还款
              </div>
              <div class="show2 flex">
                {{ utils.moneyFormat(companyInfo.capStat.waitCollectAmount) }}
              </div>
            </el-col>
          </el-row>
        </ics-page-inner>
        <div class="area-title" />
        <div class="tabs-inner">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="企业工商信息" name="companyInfo">
              <div class="partition-area">
                <el-form ref="appForm" v-loading="loading.detail" :model="appForm" :rules="rules" label-width="170px" :label-suffix="constants.labelSuffix">
                  <ics-page-inner title="企业工商信息">
                    <template slot="btn-inner">
                      <div class="btn-inner">
                        <el-button v-if="editMode" type="primary" @click="editFirm">
                          编辑
                        </el-button>
                        <el-button v-if="!editMode" @click="cancelFirm">
                          取消
                        </el-button>
                        <debounce-button v-if="!editMode" :loading="loading.submit" type="primary" @click="submitForms">
                          提交
                        </debounce-button>
                      </div>
                    </template>
                    <el-col :span="12">
                      <el-form-item label="企业名称">
                        <ics-item-inner :prop="appForm.companyName" :format="utils.isEffectiveCommon" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="统一社会信用代码">
                        <ics-item-inner :prop="appForm.socialNo" :format="utils.isEffectiveCommon" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="!editMode" :span="12">
                      <el-form-item prop="legalPerson" label="法定代表人姓名">
                        <el-input v-model="appForm.legalPerson" placeholder="请输入法定代表人姓名" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="editMode" :span="12">
                      <el-form-item prop="legalPerson" label="法定代表人姓名">
                        <ics-item-inner :prop="appForm.legalPerson" :format="utils.isEffectiveCommon" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="!editMode" :span="12">
                      <el-form-item prop="legalIdentity" label="法定代表人身份证号码">
                        <el-input v-model="appForm.legalIdentity" placeholder="请输入法定代表人身份证号码" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="editMode" :span="12">
                      <el-form-item prop="legalIdentity" label="法定代表人身份证号码">
                        <ics-item-inner :prop="appForm.legalIdentity" :format="utils.isEffectiveCommon" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="!editMode" :span="12">
                      <el-form-item label="注册资本(元)">
                        <el-input v-model="appForm.regAmount" type="number" placeholder="请输入注册资本" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="editMode" :span="12">
                      <el-form-item label="注册资本(元)">
                        <ics-item-inner :prop="appForm.regAmount" :format="(val)=>utils.moneyFormat(val,2)" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="!editMode" :span="12">
                      <el-form-item label="固定电话">
                        <el-input v-model="appForm.tel" placeholder="请输入固定电话" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="editMode" :span="12">
                      <el-form-item label="固定电话">
                        <ics-item-inner :prop="appForm.tel" :format="utils.isEffectiveCommon" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="!editMode" :span="12">
                      <el-form-item label="电子邮箱">
                        <el-input v-model="appForm.email" placeholder="请输入电子邮箱" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="editMode" :span="12">
                      <el-form-item label="电子邮箱">
                        <ics-item-inner :prop="appForm.email" :format="utils.isEffectiveCommon" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="!editMode" :span="12">
                      <el-form-item label="传真">
                        <el-input v-model="appForm.fax" placeholder="请输入传真" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="editMode" :span="12">
                      <el-form-item label="传真">
                        <ics-item-inner :prop="appForm.fax" :format="utils.isEffectiveCommon" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="!editMode" :span="12">
                      <el-form-item prop="contactName" label="联系人姓名">
                        <el-input v-model="appForm.contactName" placeholder="请输入联系人姓名" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="editMode" :span="12">
                      <el-form-item prop="contactName" label="联系人姓名">
                        <ics-item-inner :prop="appForm.contactName" :format="utils.isEffectiveCommon" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="!editMode" :span="12">
                      <el-form-item prop="contactPhone" label="联系人电话">
                        <el-input v-model="appForm.contactPhone" placeholder="请输入联系人电话" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="editMode" :span="12">
                      <el-form-item prop="contactPhone" label="联系人电话">
                        <ics-item-inner :prop="appForm.contactPhone" :format="utils.isEffectiveCommon" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="!editMode" :span="24">
                      <el-form-item label="公司网站">
                        <el-input v-model="appForm.net" placeholder="请输入公司网站" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="editMode" :span="24">
                      <el-form-item label="公司网站">
                        <ics-item-inner :prop="appForm.net" :format="utils.isEffectiveCommon" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="!editMode" :span="12">
                      <el-form-item prop="usEnterpriseAddress" label="注册地址">
                        <address-picker v-model="appForm.usEnterpriseAddress" :is-show-address-info="isShowAddressInfo" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="editMode" :span="12">
                      <el-form-item label="注册地址">
                        <address-picker-detail v-model="appForm.usEnterpriseAddress" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="!editMode" :span="12">
                      <el-form-item prop="dsEnterpriseAddress" label="办公地址">
                        <address-picker v-model="appForm.dsEnterpriseAddress" :is-show-address-info="isShowAddressInfo" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="editMode" :span="12">
                      <el-form-item label="办公地址">
                        <address-picker-detail v-model="appForm.dsEnterpriseAddress" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="!editMode" :span="24">
                      <el-form-item label="经营范围">
                        <el-input v-model="appForm.scope" placeholder="请输入经营范围(限300字)" :maxlength="300" type="textarea" />
                      </el-form-item>
                    </el-col>
                    <el-col v-if="editMode" :span="24">
                      <el-form-item label="经营范围">
                        <ics-item-inner :prop="appForm.scope" :format="utils.isEffectiveCommon" />
                      </el-form-item>
                    </el-col>
                  </ics-page-inner>
                </el-form>
              </div>
            </el-tab-pane>
            <el-tab-pane label="业务覆盖城市" name="second">
              <ics-page-inner title="业务覆盖城市">
                <template slot="btn-inner">
                  <div class="btn-inner">
                    <el-button v-if="viewCity" type="primary" @click="editCity">
                      编辑
                    </el-button>
                    <el-button v-if="!viewCity" @click="cancelCity">
                      取消
                    </el-button>
                    <debounce-button v-if="!viewCity" :loading="loading.submit" type="primary" @click="submitCityForms">
                      提交
                    </debounce-button>
                  </div>
                </template>
                <div class="detail-inner">
                  <div class="function-mian">
                    <div class="function-header">
                      选择城市：
                    </div>
                    <el-tree
                      ref="areaTree"
                      v-loading="loading.tab"
                      :data="cityList"
                      node-key="code"
                      show-checkbox
                      :default-checked-keys="theCityList"
                      :expand-on-click-node="false"
                    >
                      <span slot-scope="{ node, data }" class="custom-tree-node">
                        <span class="function-tree">{{ data.name }}</span>
                      </span>
                    </el-tree>
                  </div>
                </div>
              </ics-page-inner>
            </el-tab-pane>
            <el-tab-pane label="金融产品" name="product">
              <ics-product-inner />
            </el-tab-pane>
            <el-tab-pane label="授信信息" name="credit">
              <ics-credit-inner />
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-form>
    </div>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import AddressPicker from '@/components/template/address-picker'
import AddressPickerDetail from '@/components/template/address-picker-detail'
import IcsCreditInner from './components/credit-inner'
import IcsProductInner from './components/product-inner'
import axios from "axios";
export default {
  components: { IcsCreditInner, IcsProductInner, AddressPicker, AddressPickerDetail },
  mixins: [routeEnterMixin],
  data () {
    const validatePhone  = (rule, value, callback) => {
      if (/^\d{3}-\d{8}|\d{4}-\d{7,8}$/.test(value) || /^1[3|4|5|7|8|9][0-9]\d{8}$/.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确的手机号或固定电话'))
      }
    }
    return {
      isShowAddressInfo: false,
      activeName: 'companyInfo',
      theCityList: [],
      cityList: [],
      editMode: true,
      viewCity: true,
      appForm: {
      },
      companyInfo: {
        capRel: {},
        capStat: {}
      },
      loading: {
        detail: false,
        submit: false,
        tab: false
      },
      rules: {
        tel: [
          { required: true, message: '固定电话', trigger: 'change' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        companyName: this.changeRules({ name: '企业名称', required: true }),
        socialNo: this.changeRules({ name: '统一社会信用代码', required: true }),
        legalPerson: this.changeRules({ name: '法定代表人姓名', required: true }),
        legalIdentity: this.changeRules({ name: '法定代表人身份证号码', required: true }),
        contactName: this.changeRules({ name: '联系人姓名', required: true }),
        contactPhone: [
          { required: true, message: '联系人电话', trigger: 'change' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        usEnterpriseAddress: [
          this.rulesToolkit.rules.required({ name: '注册地址', trigger: 'change' })
        ],
        dsEnterpriseAddress: [
          this.rulesToolkit.rules.required({ name: '办公地址', trigger: 'change' })
        ],
      }
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail() {
      this.loading.detail = true
      this.api.company.capital.getCapDetail().then(result => {
        const data = result.data.data || {}
        let regAddr = []
        let workAddr = []
        if (data.firm.regAddr) {
          regAddr = data.firm.regAddr.split(',')
          data.firm.usEnterpriseAddress = [regAddr[0], regAddr[1], regAddr[2], data.firm.regAddrDetail]
        }
        if (data.firm.workAddr) {
          workAddr = data.firm.workAddr.split(',')
          data.firm.dsEnterpriseAddress = [workAddr[0], workAddr[1], workAddr[2], data.firm.workAddrDetail]
        }
        const cityKeys = []
        data.cityList.forEach(item => {
          cityKeys.push(item.areaCode)
        })
        this.theCityList = cityKeys
        this.companyInfo.capRel = data.capRel
        this.companyInfo.capStat = data.capStat
        this.appForm = data.firm || {}
        this.isShowAddressInfo = false
      }).finally(() => {
        this.loading.detail = false
      })
    },
    getCityList () {
      this.loading.tab = true
      axios.get('/tenant/v1/mstDictInfo/selectDictInfo/area').then(res => {
        const cityData = JSON.stringify(res.data.data)
        const optionsJson = JSON.parse(cityData)
        optionsJson.map((item, index) => {
          item.children = item.subList
          if (this.viewCity) {
            item.disabled = true
            if (item.children) {
              item.children.forEach(sub => {
                sub.disabled = true
              })
            }
          }
        })
        this.cityList = optionsJson
      }).finally(() => {
        this.loading.tab = false
      })
    },
    editFirm () {
      this.getDetail()
      this.editMode = false
    },
    cancelFirm () {
      this.getDetail()
      this.editMode = true
    },
    editCity () {
      this.getCityList()
      this.viewCity = false
    },
    cancelCity () {
      this.getCityList()
      this.viewCity = true
    },
    submitForms () {
      this.$refs.appForm.validate((valid) => {
        if (valid) {
          this.loading.submit = true
          const data = this.appForm
          let regAddr = ''
          let regAddrDetail = ''
          let workAddr = ''
          let workAddrDetail = ''
          if (data.usEnterpriseAddress !== undefined && data.usEnterpriseAddress.length > 0) {
            const regAddr1 = [data.usEnterpriseAddress[0], data.usEnterpriseAddress[1], data.usEnterpriseAddress[2]]
            regAddr = regAddr1.join(',')
            regAddrDetail = data.usEnterpriseAddress[3]
          }
          if (data.dsEnterpriseAddress !== undefined && data.dsEnterpriseAddress.length > 0) {
            const workAddr1 = [data.dsEnterpriseAddress[0], data.dsEnterpriseAddress[1], data.dsEnterpriseAddress[2]]
            workAddr = workAddr1.join(',')
            workAddrDetail = data.dsEnterpriseAddress[3]
          }
          const info = {
            contactName: data.contactName,
            contactPhone: data.contactPhone,
            email: data.mail,
            regAmount: data.regAmount,
            tel: data.tel,
            fax: data.fax,
            id: data.id,
            legalPerson: data.legalPerson,
            legalIdentity: data.legalIdentity,
            remark: data.remark,
            scope: data.scope,
            net: data.net,
            regAddr: regAddr,
            regAddrDetail: regAddrDetail,
            workAddr: workAddr,
            workAddrDetail: workAddrDetail
          }
          this.api.company.capital.updateCap(info).then(result => {
            if (result.data.success === true) {
              this.loading.submit = false
              this.$message.success('操作成功')
              this.editMode = true
              this.getDetail()
            } else {
              this.$message.error(result.data.message)
              this.loading.submit = false
            }
          }).catch(e => {
            this.loading.submit = false
          })
        }
      })
    },
    submitCityForms () {
      this.loading.submit = true
      const selectCity = this.$refs.areaTree.getCheckedNodes()
      const cityList = []
      selectCity.forEach((val, index, arr) => {
        if (val.code) {
          const city = {}
          city.areaCode = val.code
          city.areaName = val.name
          if (val.parentCode) {
            city.parentCode = val.parentCode
          }
          cityList.push(city)
        }
      })
      if (cityList.length >= 1) {
        this.api.company.capital.firmCitySave(this.appForm.id, cityList).then(result => {
          if (result.data.success === true) {
            this.loading.submit = false
            this.$message.success('操作成功')
            this.viewCity = true
            this.getCityList()
            this.getDetail()
          } else {
            this.$message.error(result.data.message)
          }
        }).catch(e => {
          this.loading.submit = false
        })
      } else {
        this.$message.warning('请选择业务覆盖城市')
      }
    },
    handleClick () {
      if (this.activeName === 'second') {
        this.getCityList()
      }
    }
  }
}
</script>

<style scoped>
.show{
  line-height:60px;
  font-size:15px;
  font-weight: bold;
}
.show2{
  line-height:80px;
  font-size:30px;
  font-weight: bold;
  /* font-family:'Times New Roman', Times, serif; */
  color:cornflowerblue;
}
.box{
  background-image: linear-gradient(180deg, #C9E3F6 0%, #C9E3F6 26%);
  box-shadow: 0 1px 2px 0 rgba(201,227,247,0.60);
  border-radius: 8px;
}
</style>
